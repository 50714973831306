<template>
<div id="LevelExamComplete">  
  <Header/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
<body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand text-bold me-0 col-4  text-card-big" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom"> </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" > </a> 
      </div>
    </nav> 
    <div class="nicolespace"></div>
    <div class="container text-center mt-2 mb-4 mb-md-5">
      <div class="d-flex w-100 mx-auto">
        <div class="w-50">    
           <img src="assets/img/test3.png" class="test3">  
        </div>
        <div class="w-50 text-center ps-md-5">
          <h5 class="fw-bolder my-1 my-md-3 text-card-xl text-success">推薦等級</h5>
          <h5 class="fw-bolder my-1 my-md-3 text-card-title text-success">Recommended level</h5>
          <img v-real-img="pic" src="assets/img/Upper intermediate.png" class="test3-2">  
        </div>
      </div>
    </div>     
    <div class="container  mb-4">
      <h5 class="fw-simbold my-3 text-card-title text-dark lh-base">{{description1}}</h5>
      <h5 class="fw-bolder my-4 text-card-name text-success">學習建議： </h5>
      <h5 class="fw-simbold my-3 text-card-title text-dark lh-base"> {{description2}} </h5>
      <h5 class="fw-bolder my-4 text-card-name text-success">完成任務，你的英文能力將達到： </h5>
      <ol class=" text-gray" style="list-style-image:url('assets/img/star1.png'); ">
        <li class="text-card-title mb-2 mb-md-3"> {{tip1}} </li>
        <li class="text-card-title mb-2 mb-md-3"> {{tip2}} </li>
        <li class="text-card-title mb-2 mb-md-3"> {{tip3}} </li>
        <li class="text-card-title mb-2 mb-md-3"> {{tip4}} </li>
        <li class="text-card-title mb-2 mb-md-3"> {{tip5}} </li>
      </ol>
    </div>   

    <div class="text-center my-4">
      <div class="fw-simbold my-3 text-card-name text-dark"> 跟著學習計畫走，實力養成沒煩惱 </div>
      <div class="fw-simbold my-3 text-card-name text-success"><img src="assets/img/star1.png"> 立即啟動個人專屬學習計畫 </div>
    </div>
    <div class="container my-3   text-center">
      <button type="button" class="btn  btn-lg  btn-success rounded-pill w-95  text-s" data-bs-dismiss="modal" @click="openCustomLevelSetting()"> 前往自訂課程 </button>
    </div>
    <div class="nicolespace"></div>
    <div class="nicolespace"></div>  
</body>
  <Footer/>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";

export default {
    name: 'LevelExamComplete',
    components: {
        Header,
        Footer,
        CommonAlert2Func,
    },
    data () {
      return { 
        // api setting
        setDebugMode:false ,
        apiDebug: false,
        debugMode: false,
        apiName: "",
        form: [],

        // data
        pic: "",
        levelTestWaitDays: "30",
        isLevelTest: "N",
        description1: "N",
        description2: "N",
        tip1: "N",
        tip2: "N",
        tip3: "N",
        tip4: "N",
        tip5: "N",
        elid: "",

        // Alert
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,

      }
    },
    methods: {
      openLogin(){
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"LevelExamIntro", type:""}), this.openLoginCallback);
        this.backUrl = "";
      },
      openLoginCallback(data){

      },
      setOnBack(){
          this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },

      onBack() {
        if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
          this.$router.push(this.$route.params.back);
        } else {
          this.$router.push({
            name: "Home"
          });
        }
      },
      startTest(){
        if (this.isLevelTest == 'Y') {
          this.$router.push({
            name: "LevelExamIntroReciprocal"
          });
        } else {
          this.title = "距離前次測驗，尚未滿 " + this.levelTestWaitDays + " 天";
          this.btnNameUp = "自訂等級";
          this.btnNameDown = "之後再測";
          this.onFuncUp = this.openCustomLevel;
          this.onFuncDown = null;
          this.$refs.commonAlert2Func.onShow();
        }
      },
      openCustomLevelSetting(){
        this.$router.push({
          name: "CustomLevelSetting",
          params:{
            back: "LevelExamComplete",
            huepid: this.$route.params.huepid,
            eplid: this.$route.params.eplid,
            elid: this.elid
          }
        });
      },
  
      getAPI(){
        if (this.$route.params.eplid == undefined || this.$route.params.huepid == undefined ) {
          return ;
        }
        this.apiName="page_level_exam_complete";
        this.form["huepid"] = this.$route.params.huepid;
        this.form["eplid"] = this.$route.params.eplid;
        this.form["uid"] = this.$GlobalData.uid;
        
        if (this.$route.params && this.$route.params.dont == undefined) {
          this.form["dont"] =  "N";
        } else {
          this.form["dont"] = this.$route.params.dont;
        }


        oAC.getAllRecord(oAC.getApiParam(this), this.form ).then(res => {
            if(res && res.english_level && res.english_level[0].elid != ""){  
              this.elid = res.english_level[0].elid;
              switch(res.english_level[0].elid){
                case "level1":
                  this.level1Text();
                break;
                case "level2":
                  this.level2Text();
                break;
                case "level3":
                  this.level3Text();
                break;
                default:
                  this.level4Text();
                break;
              }
            }
          }
        ).catch((err)=> {
            oltConsole.log(err);
        });
      },
      level1Text(){
        this.description1 = "你現在可以認得一些基本的單字以及常見的日常用語，對於基本結構的句型有一些理解。在聽力方面，如果把速度放慢，可以聽懂簡易的日常對話或敘述，能夠閱讀單字、形容詞居多的廣告傳單、四格漫畫等。";
        this.description2 = "由於你現在的字彙量還不夠，在聽力與閱讀上難免有些不順暢，但千萬別灰心！選擇你有興趣的主題，並且依照學習任務的順序一一完成。把原文朗讀跟單字預習都下載到離線收聽，搭車聽、走路也聽！";
        this.tip1 = "字彙量超越2200字";
        this.tip2 = "基礎文法&句型觀念建立，如時態、肯定句、五大基本句型";
        this.tip3 = "可聽懂一般語速的日常主題、生活對話";
        this.tip4 = "閱讀短文、兒童讀物，如伊索寓言、格林童話等";
        this.tip5 = "GEPT初級、多益225+、IELTS 3+、CEFR A2、國中會考";
        this.pic = "assets/img/Elementary.png";
      },
      level2Text(){
        this.description1 = "現在的你已經具備基礎的英語能力，可以有效的掌握基本文法、句型和詞彙。對於基本句型、名詞以及形容詞有一定程度的認識。一般語速的日常對答對你來說並不困難，也可以流暢的閱讀日常生活中常見的如：兒童讀物、短篇文章等。";
        this.description2 = "由於你現在的字彙量還不夠，在聽力與閱讀上難免有些不順暢，但千萬別灰心！在使用APP學習時，建議你依照學習任務的順序一一完成。如果你想稍微加速，就把原文朗讀跟單字預習都聽兩遍吧！";
        this.tip1 = "字彙量提升到4000字";
        this.tip2 = "學習進階文法與句型，如：關係代名詞、關係副詞、讓步子句等";
        this.tip3 = "可聽懂一般語速的主題討論、廣播、演講等";
        this.tip4 = "閱讀長篇文章、生活雜誌、小說(如，哈利波特)等";
        this.tip5 = "GEPT中級、多益550+、IELTS 4+、CEFR B1、大學學科能力測驗";
        this.pic = "assets/img/Intermediate.png";
      },
      level3Text(){
        this.description1 = "閱讀讀者文摘對你來說已經不是什麼難事了！你的英文能力目前已經具備一定的單字量，對基礎與進階的文法也有相當程度上的了解，現在的你，已經可以聽懂慢速播報的新聞，閱讀方面則需要再藉由大量且廣泛的文章主題來讓你的英語力更上一層樓。";
        this.description2 = "大量的閱讀各式各樣的長篇文章，會是讓你的英語能力再更上一層樓的最好方法！不要侷限在某一個主題，多去閱讀你較少接觸的主題跟文章類型，才可以更廣闊的吸收其他領域裡的單字，並加深你的文法句型結構能力！";
        this.tip1 = "字彙量超越5500字";
        this.tip2 = "學習更深度文法與句型，如：不定詞、動名詞、比較句構等";
        this.tip3 = "可聽懂一般語速的新聞、商業對談、主題演講等";
        this.tip4 = "閱讀報導評論、商業雜誌等";
        this.tip5 = "GEPT中高級、多益785+、IELTS 5.5+、CEFR B2、CET 4";
        this.pic = "assets/img/Upper_intermediate.png";
      },
      level4Text(){
        this.description1 = "恭喜你！你現在已經具備相當廣泛的單字量，文法觀念以及句型結構能力都在一定水準之上！幾乎可稱作類母語人士的你，在觀看英文新聞、報章雜誌等等，可以說是已經沒有障礙，現在要做的就是讓你的英語力更精專、精確、精實！";
        this.description2 = "英語學習最困難的不是突破，而是「維持」！選擇高級分類的文章與影音，不要限定主題，練習直接使用英文搜尋你想要的任何資訊，以英文學習專業知識，進而增加英文的深度。觀看影音專區的文章時，把字幕關閉，開始練習進入全英語的世界吧！";
        this.tip1 = "字彙量超越7000字";
        this.tip2 = "可以精準使用艱澀或專業相關的單字、語法";
        this.tip3 = "可聽懂CNN新聞播報、母語人士的各種對話";
        this.tip4 = "閱讀專業期刊、醫學、科技等相對艱澀的主題文章";
        this.tip5 = "GEPT高級、多益945+、IELTS 6.5+、CEFR C1、CET 6";
        this.pic = "assets/img/Advanced.png";
      },


    },
    created(){  
      if (this.$GlobalData.isDebug) {
        this.$GlobalData.uid = 1;
      }
      if (this.$JsBridge.isAndroid) {
          this.setOnBack();
      }
      this.getAPI();
    }
};
</script>

